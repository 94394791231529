import React, { useRef, useState } from "react";
import "../styles/drawingPanel.scss";
import Row from "./Row";
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';

const adjectives = ["Smukke", "Ægte", "Seje", "Fantastiske", "Godhjertede", "Optimistiske", "Opmærksomme", "Hengivne", "Blide", "Generøse", "Vilde", "Ærlige", "Loyale", "Sjove", "Tilgivende", "Oprigtige", "Uselviske", "Nyttige", "Følsomme", "Empatiske", "Ikke-fordømmende", "Pålidelige", "Sjove", "Støttende", "Trofaste", "Omsorgsfulde", "Kærlige", "Søde", "Pålidelige", "Talentfulde", "Modige", "Muntre", "Trøstende", "Medfølende", "Tankevækkende", "Forstående", "Varmhjertede", "Hurtige", "Vidunderlige", "Hårdtarbejdende", "Hjertelige", "Gyldne", "Beroligende", "Pålidelige", "Ansvarlige", "Retfærdige", "Uafhængige", "Betænksomme", " Søde", " Konsekvente", " Fantastiske", "Perfekte", "Charmerende", "Ligefremme", "Tålmodige", "Kvikke", "Vittige", "Dynamiske", "Praktiske", "Intuitive", "Underholdende", "Humoristiske", "Sjove", "Entusiastiske", "Energiske", "Eventyrlystne", "Glade", " Blødhjertete", "Tilbagelænede", "Bestemte", "Lidenskabelige", "Frygtløse", "Stærke", "Venlige", "Taknemmelige", "Dejlige", "Sympatiske", "Venskabelige", "Standhaftige", "Sandfærdige", "Ukomplicerede", "Dybe", "Uovertrufne", "Heltmodige", "Accepterende", "Kære"];
const colors = ["hvide", "elfenbensfarvede", "kornfarvede", "flødefarvede", "sandfarvede", "gule", "støvgule", "neonfarvede", "neongule", "lysegule", "lemongule", "limegule", "abrikosfarvede", "khakifarvede", "karrygule", "mørkegule", "okkerfarvede", "kobberfarvede", "ravgule", "bronzefarvede", "lysebrune", "nøddebrune", "brune", "chokoladefarvede", "teakfarvede", "kastanjebrune", "mørkebrune", "bordeaux", "pastelfarvede", "orange", "neonorange", "rosenrøde", "kirsebærrøde", "blommefarvede", "mørkerøde", "laksefarvede", "røde", "lyserøde", "vinrøde", "skarlagenrøde", "rosa", "pink", "magenta", "lyselilla", "lilla", "mørkelilla", "violette", "lavendelfarvede", "dueblå", "lyseblå", "himmelblå", "indigo", "azurblå", "kornblå", "marineblå", "mørkeblå", "ultramarine", "blå", "støvblå", "turkisblå", "turkisgrønne", "grønne", "støvgrøn", "lysegrønne", "olivengrøn", "lemongrønne", "græsgrønne", "grønne",  "limegrønne", "pistaciegrønne", "neongrønne", "smaragdgrønne", "stikkelsbærfarvede", "lysegrå", "grå", "mørkegrå", "koksgrå", "sorte"];
const flowers = ["forglemmigej", "hestemynte", "hibiscus", "hosta", "hyld", "kornblomst", "lavendel", "løgblomst", "morgenfrue", "primula", "rose", "solsikke", "stedmoderblomst", "stokrose", "syren", "tagetes", "tallerkensmækker", "iris", "vintergæk", "påskelilje", "bonderose", "silkepæon", "rhododendron", "tidsel", "mælkebøtte", "ærteblomst", "akeleje"];


export default function DrawingPanel(props) {
  const [submitted, setSubmitted] = useState(false);
  const [clownName, setClownName] = useState("");

  function submitClownFromBlob(blob) {
 
    var fd = new FormData();
  
    const { uniqueNamesGenerator } = require('unique-names-generator');
 
    const randomName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, flowers] }); // big_red_donkey
  
    fd.append('fileToUpload', blob, randomName+".png");
  
    var request = new XMLHttpRequest();

    setClownName(randomName)

    request.onreadystatechange = function() {
      if (this.readyState === 4 && (this.status >= 200 && this.status < 400)) {
        setClownName(randomName)
      }
    };
    request.open("POST", "https://pixelklovne.danskeplejehjemsklovne.dk/upload_clown.php", true);
//  request.open("POST", "upload_clown.php", true);
  request.send(fd);
  
  }
 
  
  function submitClownFromComponent (node) 
  {
    if(!node.current) {
        throw new Error("'node' must be a RefObject")
    }

    const html2CanvasOptions = {}
    const type = 'image/png'
    const element = ReactDOM.findDOMNode(node.current);
    setSubmitted(true);
    
    return html2canvas(element, {
        scrollY: -window.scrollY,
        useCORS: true,
        ...html2CanvasOptions
    }).then(canvas => {canvas.toBlob(submitClownFromBlob, type, 1.0);
    });
  };


  const { width, height, selectedColor } = props;

  const panelRef = useRef();

  let rows = [];

  for (let i = 0; i < height; i++) {
    rows.push(<Row key={i} width={width} selectedColor={selectedColor} row={i} />);
  }

  return (
    <div id="drawingPanel">
      <div id="pixels" ref={panelRef}>
        {rows}
      </div>
      {submitted ? (
        
        <>
        <h1>Tak!</h1>
        { clownName !== "" ?
        <p class="clownname"><b>Klovnen kom til at hedde:</b><br/> {clownName}</p>
        : <></>
        }
        <br/>
        </>
      ):(
        <button onClick={() => submitClownFromComponent(panelRef)} className="button">
        Indsend min klovn!
      </button>
      )}
    </div>
  );
}
