import React, { useState } from "react";
import "../styles/pixel.scss";

function getColor(col,row)
{
  var color = "#ffffff";

  var dist_to_center_sq = (col - 11.5)*(col - 11.5) + (row - 11.5)*(row - 11.5) 

  if (dist_to_center_sq<(10*10))
  {
    color = "#e0ac69";
  }

  if (dist_to_center_sq>(7*7) && dist_to_center_sq<(8.5*8.5) && row > 15)
  {
    color = "#000000";
  }


  var dist = (col - 11.5)*(col - 11.5) + (row - 13)*(row - 13) 
  if (dist<(2*2))
  {
    color = "#f44336";
  }

  if (row == 9)
  {
    if (col == 9 || col == 14)
    color = "#000000";
  }

  return color;
}

export default function Pixel(props) {
  const { selectedColor, col, row } = props;

  const [pixelColor, setPixelColor] = useState(getColor(col,row));

  const [oldColor, setOldColor] = useState(pixelColor);
  const [canChangeColor, setCanChangeColor] = useState(true);

  function applyColor() {
    setPixelColor(selectedColor);
    setCanChangeColor(false);
  }

  function changeColorOnHover() {
    setOldColor(pixelColor);
    setPixelColor(selectedColor);
  }

  function resetColor() {
    if (canChangeColor) {
      setPixelColor(oldColor);
    }

    setCanChangeColor(true);
  }

  return (
    <div
      className="pixel"
      onClick={applyColor}
      onMouseEnter={changeColorOnHover}
      onMouseLeave={resetColor}
      style={{ backgroundColor: pixelColor }}
    ></div>
  );
}
