import React, { useState } from "react";
import "../styles/editor.scss";
import { CirclePicker } from "react-color";
import DrawingPanel from "./DrawingPanel";

export default function Editor() {
  
  const [panelWidth, setPanelWidth] = useState(24);
  const [panelHeight, setPanelHeight] = useState(24);
  const availableColors = [ "#ffffff", "#f44336", "#000000", "#99cc33", "#ffb400", "#189ad3" ,"#343434", "#8d5524", "#c68642", "#e0ac69", "#f1c27d", "#ffdbac", "#ffeb3b", "#ffc107", "#ff9800", "#d44343", "#ff77bc", "#600d8b"] 
  //const [hideOptions, setHideOptions] = useState(true);
  //const [hideDrawingPanel, setHideDrawingPanel] = useState(false);
  //const [buttonText, setButtonText] = useState("nulstil");
  const [selectedColor, setColor] = useState("#f44336");

  function initializeDrawingPanel() {
    //setHideOptions(!hideOptions);
    //setHideDrawingPanel(!hideDrawingPanel);


  }

  function changeColor(color) {
    setColor(color.hex);
  } 

  return (
    <div id="editor">
      <h1>Donér en pixelklovn</h1>


      { (
        <CirclePicker color={selectedColor} colors={availableColors} onChangeComplete={changeColor} />
      )}
 
      { (
        <DrawingPanel
          width={panelWidth}
          height={panelHeight}
          selectedColor={selectedColor}
        /> 
      )}
    </div>
  );
}
